import React from 'react'
import { connect } from 'react-redux'
// import {  Container } from 'semantic-ui-react'
import { Dropdown, Menu } from 'semantic-ui-react'

// import OpenFile   from './topmenu-openfile'
// import NewFile    from './topmenu-newfile'
// import CloseFile  from './topmenu-closefile'
// import Login      from './topmenu-login'
// import Refresh    from './topmenu-refresh'
// import Share      from './topmenu-share'
// import Publish    from './topmenu-publish'
// import AppAvatar  from './app-avatar'
// import Tabs       from '../containers/tabcontrol'

const TopNav = ({children}) => <Menu inverted fixed='top' id='top-nav'>
  <Menu.Item name='trystal' header>Trystal</Menu.Item>
   <Dropdown item simple text='File'>
{/* 
     <Dropdown.Menu>
       {children}
     </Dropdown.Menu>
*/}
     </Dropdown> 
{/*</Dropdown>     
     <Tabs /> 
     <Menu.Menu position='right'>
     < AppAvatar />
     </Menu.Menu> */}
</Menu>


// // <Menu vertical inverted fixed='left'><Menu.Item name='Trystal'/><Menu.Item name='' /><Timeline />{children}</Menu>

const Component = () => <TopNav>TopNav</TopNav>
//const Component = ({isAnon}) => isAnon 
//   ? <TopNav><Login /></TopNav>
//   : <TopNav>
//       <OpenFile />
//       <NewFile />
//       <Refresh />
//       <Share />
//       <Publish />
//       <CloseFile />
//     </TopNav>

const mapStateToProps = () => ({})
// const mapStateToProps = ({appvars:{isAnon}}) => {
//    return { isAnon }
//  }

export default connect(mapStateToProps,  null)(Component)


