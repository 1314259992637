import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

import reducer from './reducer'

const store = createStore(reducer,applyMiddleware(thunk))


//   render(<Provider store={store}>{app}</Provider>, document.getElementById('app'))
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// //import AppMobile  from './mobile/containers/app'
// import { setInitialState } from './actions'
// import { 
//      ajaxLoadTimeline, 
//      ajaxLoadSubscriptions,
//      ajaxLoadFileList,
//      ajaxLoadTrist,
//      ajaxFlushDirtyTrists,
//      startSaveTimer,
//      fetchPublications
// } from './actions-thunk'

// const isMobile = /Mobi/.test(navigator.userAgent) 

// const whoami = async function() {
//   const resp = await fetch('/api/1.0/whoami', { credentials:  'include'})
//   const json = await resp.json()
//   return json
// }

// whoami()
// .then(({uid, username}) => {
//   const isAnon = !uid
//   store.dispatch(ajaxLoadTimeline())
//   if(isAnon) store.dispatch(setInitialState({uid:null, username:null, isAnon, isMobile})) 
//   else {
//     store.dispatch(setInitialState({uid, username, isAnon, isMobile})) 
//     store.dispatch(ajaxLoadFileList(uid))
//     store.dispatch(ajaxLoadSubscriptions(uid))
//     store.dispatch(fetchPublications())
//     store.dispatch(startSaveTimer())
//     // store.dispatch(ajaxLoadTemplates())
//     window.onbeforeunload = () => {
//         store.dispatch(ajaxFlushDirtyTrists())
//         if(store.getState().vtrists.find(v => v.isDirty)) return 'Leave Trystal?'
//         else return
//     }
//   }
//   //const app = isMobile ? <AppMobile /> : <AppDesktop/>
//   const app = <AppDesktop/> // until the mobile side comes back to life

//   render(<Provider store={store}>{app}</Provider>, document.getElementById('app'))

//   // if(tristSpec) {
//   //   const {uid,filename,view} = tristSpec
//   //   const viewnum = (view === 'published') ? 2 : 1
//   //   const turl = new TryUrl(uid,filename,viewnum)
//   //   store.dispatch(ajaxLoadTrist(turl))
//   // }
// })



// // ajaxLoadTemplates, awaiting rehydration    
// // declare var username: string
// // declare var perms: string
// // declare var uid : string  TS version


