import React from 'react';
import { connect } from 'react-redux'
import './App.css';
import TopNav             from './TopNav'
///////////////////// component /////////////////////////////
const App = () => <div className="App" id='app-container'>
  <TopNav />
</div>

// import $ from 'jquery'
// import React from 'react'
// import { Menu, Dropdown, Button } from 'semantic-ui-react'

// import Timeline           from '../timeline'
// import VisibleTrist       from '../containers/visible-trist'

// import FilenameDialog     from '../dialogs/filename'
// import OpenfileDialog     from '../dialogs/openfile'
// import PublishDialog      from '../dialogs/publish'
// import UsernameDialog     from '../dialogs/username'
// import ShareDialog        from '../dialogs/share'
// import FindDialog         from '../dialogs/find'

// import Toast              from '../controls/toast'
// import TimelineMenuItem   from '../controls/leftnav-timeline'

// // import WidgetsDialog = require('../dialogs/widgets-dialog')
// // <WidgetsDialog templates={templates} visible={widgetsDialogVisible} />  // awaiting rehydration

// const S3 = {height:'100vh', flexDirection:'column', WebkitFlexDirection:'column', overflow:'hidden', position:'relative', flexGrow:1 }

// const outerContainerProps = {
//   ref       : 'trystal', 
//   id        : 'trystal', 
//   style     : S3, 
//   className : 'flexy', 
//   tabIndex  :-1 
// }

// const buildTristProps = tristUrl => ({
//   tristUrl,
//   mode : null,
//   format : null,
//   isFocused : null,
//   onClose : null,
//   onShare : null,
//   onPublish : null
// })


// class DesktopApp extends React.Component{
//   componentDidMount() {
//     const $trystal = $('#trystal')
//     if(!$trystal.is(':focus')) $trystal.focus()
//   }
//   render() {
//     const {uid, tristUrl, vtrists, onKeyDown} = this.props
//     const vtrist = vtrists.find(vt => vt.tristUrl === tristUrl)
//     const title = vtrist ? vtrist.title : null
//     const handleKeyUp = e => {
//       e.preventDefault()
//       e.stopPropagation()
//     }
//x     return <div id='app-container'> 
//       <TopNav />
//       <Menu id='left-nav' vertical fixed='left'><TimelineMenuItem /></Menu>
//       <Timeline />
//       {
//         vtrists.map(vt => {
//           const visible = vt === vtrist
//           const {tristUrl} = vt
//           const key = tristUrl
//           return <VisibleTrist key={key} hidden={!visible} {...buildTristProps(tristUrl, this.props)} />
//         })                
//       }
//       <PublishDialog {...{tristUrl, title}} />
//       <FindDialog tristUrl={tristUrl} />
//       <ShareDialog tristUrl={tristUrl} />
//       <FilenameDialog uid={uid} />
//       <UsernameDialog />
//       <OpenfileDialog />
//       <Toast />
//x     </div>
//   }
// }


/////////////////////// end of component //////////////////////////////

const mapStateToProps = state => {
  // const {appvars:{uid}, activePage:{tristUrl}, vtrists} = state
  // return { uid, vtrists, tristUrl }
  return {}
}
const mapDispatchToProps = dispatch => {
  return {
    //onKeyDown       : (e, tristId/*, vtrists*/) => onKeyDown(dispatch, e, tristId /*, vtrists*/)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)


//////////////////////////////////////////
// import Component from '../components/app'
// import { dlgFindShow,addLine, insertLine,undo, redo } from '../../actions'

// import { saveAndCloseTrist } from '../../actions-thunk'

// import { keyCodeOf, KeyCodes as KC } from '@trystal/keys'
// const showFindDialog = (dispatch, tristId) => {
//   dispatch(dlgFindShow(tristId))
// }

// const handleIt = (e, fn, ...rest) => {
//   e.preventDefault()
//   e.stopPropagation()
//   fn(...rest)
// }

// const onKeyDown = (dispatch, e, tristId) => {
//   const keyCode = keyCodeOf(e)
//   switch(keyCode) {
//     case KC.CTRLF:     return handleIt(e, dispatch, showFindDialog, tristId)
//     case KC.F10: return handleIt(e, dispatch, saveAndCloseTrist(tristId))    // we need to dispatch a second action here or...
//     case KC.ENTER:  return handleIt(e, dispatch, addLine(tristId))
//     case KC.INSERT: return handleIt(e, dispatch, insertLine(tristId))
//     case KC.CTRLZ:  return handleIt(e, dispatch, undo(tristId)) 
//     case KC.CTRLY:  return handleIt(e, dispatch, redo(tristId))
//   }
//   e.preventDefault()
//   e.stopPropagation()
// }

